<template>
  <nuxt-link :to="{ name: 'me-faq' }" class="help-button-component">
    <img class="image" src="~/assets/img/HelpButton.png" />
  </nuxt-link>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.help-button-component {
  @apply absolute top-0 right-0 mt-2 mr-2 z-20 cursor-pointer;
}

.image {
  @apply h-10;
}
</style>
